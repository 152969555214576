import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CategoryTable from '../../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../../components/Shared/Modals/CategoryModal';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { setCourse } from '../../../slices/coursesSlice';
import { updateMyCourses, addMyCourse, deleteMyCourse } from '../../../services/operations/courses';

const MyCourses = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { MyCourses, Course } = useSelector((state) => state.courses);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitted },
        reset,
    } = useForm();


    useEffect(() => {
        reset(Course)
    }, [reset, Course])
    const onSubmit = (data) => {
        const { name, slug, type } = data;
        const metaData = {
            hasQuiz: data?.hasQuiz || false,
            totalQuiz: data?.totalQuiz || 0,
            hasContest: data?.hasContest || false,
            image_link: data?.image_link || '',
            totalContest: data?.totalContest || 0,
            totalProblems: data?.totalProblems || 0,
            totalTopics: data?.totalTopics || 0,
        }
        if (isEdit) {
            const { id } = Course;
            dispatch(updateMyCourses({ id, name, slug, type, metaData }));
            setModalOpen(false);
        } else {
            dispatch(addMyCourse({ name, slug, type, metaData }, setModalOpen, reset));
        }
    };

    const handleSubjectEdit = (e, courseData) => {
        e.stopPropagation();
        const { id, name, slug, type, metaData } = courseData;
        const data = {
            id,
            name,
            slug,
            type,
            hasQuiz: metaData?.hasQuiz || false,
            totalQuiz: metaData?.totalQuiz || 0,
            hasContest: metaData?.hasContest || false,
            image_link: metaData?.image_link || '',
            totalContest: metaData?.totalContest || 0,
            totalProblems: metaData?.totalProblems || 0,
            totalTopics: metaData?.totalTopics || 0,
        };
        reset(data)
        dispatch(setCourse(data));
        setModalOpen(true);
        setIsEdit(true);
    };

    const handleDeleteSubject = (course_slug, setDeleteModalOpen) => {
        dispatch(deleteMyCourse(course_slug));
        setDeleteModalOpen(false);
    };

    return (
        <div className='flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className='px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto'>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <h2 className='text-2xl font-bold text-[#212121] dark:text-zinc-200'>My Courses</h2>
                        </div>

                        <div className='flex gap-y-2 flex-col justify-start'>
                            {MyCourses.length > 0 ? (
                                <div className='flex flex-col p-4 '>
                                    <CategoryTable
                                        page='MyCourses'
                                        setIsEdit={setIsEdit}
                                        topics={MyCourses}
                                        setModalOpen={setModalOpen}
                                        modalOpen={modalOpen}
                                        handleEdit={handleSubjectEdit}
                                        handleDelete={handleDeleteSubject}
                                    />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Subjects to show
                                </div>
                            )}
                        </div>
                        <CategoryModal title='Add Courses' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Name
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='name'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='name'
                                                    type='text'
                                                    name='name'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='name'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        const filteredValue = e.target.value
                                                            .toLowerCase()
                                                            .replace(/[\s/]/g, '-');
                                                        setValue('slug', filteredValue);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && (
                                            <p className='text-red-600 text-sm italic'>{errors.name.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Slug
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='slug'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='slug'
                                                    type='text'
                                                    name='slug'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='slug'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                validate: (value) => {
                                                    const validSlugRegex = /^[a-zA-Z0-9_-]+$/;
                                                    if (!validSlugRegex.test(value)) {
                                                        return 'Slug can only contain letters, numbers, underscores, and hyphens';
                                                    }
                                                    return true;
                                                },
                                            }}
                                        />
                                        {isSubmitted && errors.slug && (
                                            <p className='text-red-600 text-sm italic'>{errors.slug?.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Type
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='type'
                                            defaultValue='CategoryOnly'
                                            control={control}
                                            render={({ field }) => (
                                                <select {...field} className='bg-zinc-900 rounded-md w-full'>
                                                    <option value='CategoryOnly'>Categories Only</option>
                                                    <option value='CategoryAndSubCategory'>
                                                        Categories And Subcategories
                                                    </option>
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors.type && (
                                            <p className='text-red-600 text-sm italic'>{errors.type.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Image Link
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='image_link'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='image_link'
                                                    type='text'
                                                    name='image_link'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Image Link'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                            rules={{
                                                required: 'Image Link is required',
                                            }}
                                        />
                                        {isSubmitted && errors.image_link && (
                                            <p className='text-red-600 text-sm italic'>{errors.image_link.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Contests
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalContest'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalContests'
                                                    type='number'
                                                    name='totalContests'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Contest'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalContest && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalContest.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Problems
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalProblems'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalProblems'
                                                    type='number'
                                                    name='totalProblems'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Problems'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalProblems && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalProblems.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Topics
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalTopics'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalTopics'
                                                    type='number'
                                                    name='totalTopics'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Topics'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalTopics && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalTopics.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Quiz
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalQuiz'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalQuiz'
                                                    type='number'
                                                    name='totalQuiz'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Quiz'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalQuiz && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalQuiz.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Has Contest?
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='hasContest'
                                            control={control}
                                            render={({ field }) => (
                                                <select {...field} className='bg-zinc-900 rounded-md w-full'>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors.hasContest && (
                                            <p className='text-red-600 text-sm italic'>{errors.hasContest.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Has Quiz?
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='hasQuiz'
                                            control={control}
                                            render={({ field }) => (
                                                <select {...field} className='bg-zinc-900 rounded-md w-full'>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors.hasQuiz && (
                                            <p className='text-red-600 text-sm italic'>{errors.hasQuiz.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mt-6'>
                                    <div className='w-full px-3'>
                                        <button type='submit' className='btn-brand-2 w-full'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default MyCourses;
