import React, { useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../slices/syllabusSlice';

const UploadImage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');
    const [fileLink, setFileLink] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
    const [selectedProblemId, setSelectedProblemId] = useState('');
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
    const [selectedProblemName, setSelectedProblemName] = useState('');
    const [selectedCategoryHasSubcategories, setSelectedCategoryHasSubcategories] = useState(null);

    const dispatch = useDispatch();

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('No file selected');
            return;
        }
        if (selectedCategoryId === '' || selectedProblemId === '' || fileName === '') {
            toast.error("Please fill all the fields");
            return;
        }

        let formData = new FormData();
        formData.append('image', file);
        formData.append('fileName', fileName);
        formData.append('categoryName', selectedCategoryName);
        formData.append('subCategoryName', selectedSubCategoryName);
        formData.append('problemName', selectedProblemName);

        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/uploadImagePremium`, formData, {
                headers: { 'Content-type': 'multipart/form-data' },
                withCredentials: true,
            });

            if (response.data.success) {
                setImagePreview(null);
                setFileLink(response.data.link);
                setFileName('');
                setSelectedCategoryId('');
                setSelectedSubCategoryId('');
                setSelectedProblemId('');
                setSelectedCategoryName('');
                setSelectedSubCategoryName('');
                setSelectedProblemName('');
                setSelectedCategoryHasSubcategories(null);
                toast.success("File Uploaded Successfully");

            }
        } catch (error) {
            console.error(error);
        }
        toast.dismiss(toastId)
        dispatch(setLoading(false))
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setImagePreview(URL.createObjectURL(selectedFile));
        } else {
            setImagePreview(null);
        }
    };
    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        const selectedCategory = cleanedCategories.find(cat => cat.id === selectedCategoryId);
    
        if (selectedCategory) {
            setSelectedCategoryId(selectedCategory.id);
            setSelectedCategoryName(selectedCategory.name);
            setSelectedCategoryHasSubcategories(selectedCategory.hasSubcategories);  
            setSelectedSubCategoryId('');  // Reset subcategory on category change
            setSelectedSubCategoryName(''); // Reset subcategory name
            setSelectedProblemId('');  // Reset problem on category change
            setSelectedProblemName(''); // Reset problem name
        }
    };

    const handleSubCategoryChange = (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        setSelectedSubCategoryId(e.target.value);
        setSelectedSubCategoryName(e.target.options[selectedIndex].text);
        setSelectedProblemId(''); // Reset problem on subcategory change
        setSelectedProblemName(''); // Reset problem name
    };

    const handleProblemChange = (e) => {
        const selectedIndex = e.target.options.selectedIndex;
        setSelectedProblemId(e.target.value);
        setSelectedProblemName(e.target.options[selectedIndex].text);
    };

  
    
    const {  AllCategories, AllSubCategories, AllDSAProblems, AllQuizProblems, AllTheoryProblems } = useSelector((state) => state.new_syllabus);


    const cleanedCategories = AllCategories.map(subject=>subject.categories).flat()

    // From the selected category, find the subcategory with the specified selectedSubCategoryId
    const subcategory = AllSubCategories.filter(subcat =>  selectedCategoryHasSubcategories && subcat.category_id === selectedCategoryId);

    // Retrieve the problems array from the found subcategory
    const filteredProblems = [...AllDSAProblems, ...AllQuizProblems, ...AllTheoryProblems].filter(prob => selectedCategoryHasSubcategories ? prob.subcategory_id === selectedSubCategoryId : prob.category_id === selectedCategoryId);

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex items-start justify-center'>
                            <form onSubmit={handleFileUpload} encType='multipart/form-data' className='flex flex-col gap-x-4 w-1/2'>
                                {imagePreview && <img src={imagePreview} alt="Preview" className="mx-auto h-60 w-60 object-contain" />}
                                <label htmlFor='dropzone-file' className='flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 dark:border-zinc-600 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-dark_50 dark:hover:bg-bray-800'>
                                    <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                                        <svg className='w-4 h-4 mb-3 text-gray-400' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'></path>
                                        </svg>
                                        <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'><span className='font-semibold'>Click to upload</span> or drag and drop</p>
                                        <p className='text-xs text-gray-500 dark:text-gray-400'>PNG, JPG or JPEG (Max. 1mb)</p>
                                    </div>
                                    <input id='dropzone-file' className='hidden' type='file' name='image' accept='image/*' onChange={handleFileChange} />
                                </label>
                                <select value={selectedCategoryId} onChange={(e) => handleCategoryChange(e)} className='form-select mt-8'>
                                    <option value="">Select Category</option>
                                    {cleanedCategories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name} - ({category?.subject})</option>
                                    ))}
                                </select>
                                {(selectedCategoryHasSubcategories==null || selectedCategoryHasSubcategories) && (
                                <select value={selectedSubCategoryId} onChange={(e) => handleSubCategoryChange(e)} className='form-select mt-8'>
                                    <option value="">Select Subcategory</option>
                                    {subcategory?.map(sub => (
                                        <option key={sub.id} value={sub.id}>{sub.name}</option>
                                    ))}
                                </select>
                                )}
                                <select value={selectedProblemId} onChange={(e) => handleProblemChange(e)} className='form-select mt-8'>
                                    <option value="">Select Problem</option>
                                    {filteredProblems.map(prob => (
                                        <option key={prob.problem_id} value={prob.problem_id}>{prob.problem_name}</option>
                                    ))}
                                </select>

                                <input required type="text" name="fileName" id="fileName" className='form-input mt-8' placeholder='File Name' value={fileName} onChange={(e) => setFileName(e.target.value)} />
                                <button className='mt-8 bg-brand_50 px-4 py-2 rounded-md hover:bg-brand text-white w-fit' type='submit'>Upload File</button>
                            </form>
                        </div>
                        {fileLink && (
                            <div className='mt-8 mx-auto flex justify-center items-center'>
                                <div className='flex justify-between w-1/2 p-2 rounded-lg border border-zinc-400 dark:border-zinc-800 dark:bg-dark_50 bg-[#f5f5f5]'>
                                    <span className='w-full max-w-11/12'>{fileLink}</span>
                                    <button onClick={() => { navigator.clipboard.writeText(fileLink); toast.success("File Link Copied Successfully"); }}>
                                        Copy Link
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
}

export default UploadImage;
