import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { getSingleQuizProblem, updateQuizProblem } from '../../../services/operations/new-syllabus';
import { BadgeX, StepBackIcon } from 'lucide-react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Accordion from '../../../components/Accordion/Accordion';

const QuizContentEdit = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { CurrentProblem } = useSelector((state) => state.new_syllabus);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      problem_name: CurrentProblem?.problem_name || '',
      slug: CurrentProblem?.slug || '',
      questions: [
        {
          question: '',
          options: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
          correct_option: '',
          concept: { title: '', description: '' },
          example: '',
          image_link: '',
        },
      ],
    },
  });

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: 'questions',
  });

  const { slug } = params;

  useEffect(() => {
    if (slug) {
      dispatch(getSingleQuizProblem(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (Object.keys(CurrentProblem).length > 0) {
      reset({
        problem_name: CurrentProblem.problem_name,
        slug: CurrentProblem.slug,
        questions: CurrentProblem.questions,
        concept: CurrentProblem.concept,
        example: CurrentProblem.example,
        image_link: CurrentProblem.image_link,
      });
    }
  }, [CurrentProblem, reset]);

  const onSubmit = (data) => {
    const problem_data = {
      id: CurrentProblem.id,
      slug: data.slug,
      problem_name: data.problem_name,
      questions: data.questions,
    };

    dispatch(updateQuizProblem(problem_data));

    // Handle form submission
  };

  return (
    <div className='flex dark:bg-dark_50 h-screen overflow-hidden'>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
            <div className='mt-4 flex flex-col gap-10'>
              {/* BACK BUTTON */}
              <div className='flex items-center gap-4'>
                <button
                  onClick={() => navigate(-1)}
                  className='flex items-center gap-2 px-4 py-2 rounded-md shadow-md'
                >
                  <StepBackIcon />
                  <span>Back</span>
                </button>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex gap-5 mb-8'>
                  <div className='w-1/2'>
                    <div className='flex items-center gap-x-4'>
                      <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                        Problem Name <span className='text-red-500'>*</span>
                      </span>
                    </div>
                    <input
                      type='text'
                      {...register('problem_name', {
                        required: 'Problem name is required',
                      })}
                      className='form-input w-full text-gray-300'
                      placeholder='Problem name'
                    />
                    {errors.problem_name && (
                      <span className='text-red-500'>{errors.problem_name.message}</span>
                    )}
                  </div>

                  <div className='w-1/2'>
                    <div className='flex items-center gap-x-4'>
                      <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                        Slug <span className='text-red-500'>*</span>
                      </span>
                    </div>
                    <input
                      type='text'
                      {...register('slug', { required: 'Slug is required' })}
                      className='form-input w-full text-gray-300'
                      placeholder='slug'
                    />
                    {errors.slug && <span className='text-red-500'>{errors.slug.message}</span>}
                  </div>
                </div>

                <div className='flex items-center gap-4 mb-16'>
                  <span className='text-gray-800 dark:text-zinc-300 text-[26px] font-medium'>
                    Questions
                  </span>
                </div>
                <div className='flex flex-col gap-4'>
                  {questionFields.map((field, questionIndex) => (
                    <Accordion key={field.id} title={`Question ${questionIndex + 1}`}>
                      <div className='flex mb-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Question <span className='text-red-500'>*</span>
                        </span>
                        <input
                          {...register(`questions.${questionIndex}.question`, {
                            required: 'Question is required',
                          })}
                          className='form-input w-full text-gray-300'
                          placeholder='Enter your question'
                        />
                      </div>
                      {errors.questions?.[questionIndex]?.question && (
                        <span className='text-red-500'>
                          {errors.questions[questionIndex].question.message}
                        </span>
                      )}

                      <OptionFields nestIndex={questionIndex} {...{ control, register, errors }} />

                      <div className='flex items-center gap-4 mt-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Correct Option <span className='text-red-500'>*</span>
                        </span>
                        <Controller
                          name={`questions.${questionIndex}.correct_option`}
                          control={control}
                          rules={{ required: 'Correct option is required' }}
                          render={({ field }) => (
                            <select {...field} className='form-select w-full text-gray-300'>
                              <option value=''>Select correct option</option>
                              {watch(`questions.${questionIndex}.options`).map((option, index) => (
                                <option key={index} value={index}>{`Option ${index + 1}`}</option>
                              ))}
                            </select>
                          )}
                        />
                      </div>
                      {errors.questions?.[questionIndex]?.correct_option && (
                        <span className='text-red-500'>
                          {errors.questions[questionIndex].correct_option.message}
                        </span>
                      )}
                      <div className='flex items-center gap-4 mt-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Concept title
                          <span className='text-red-500'>*</span>
                        </span>
                        <Controller
                          name={`questions.${questionIndex}.concept.title`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...register(`questions.${questionIndex}.concept.title`)}
                              className='form-input w-full text-gray-300'
                              placeholder='Enter Concept'
                            />
                          )}
                        />
                      </div>

                      <div className='flex items-center gap-4 mt-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Concept Description
                          <span className='text-red-500'>*</span>
                        </span>
                        <Controller
                          name={`questions.${questionIndex}.concept.description`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...register(`questions.${questionIndex}.concept.description`)}
                              className='form-input w-full text-gray-300'
                              placeholder='Enter Description'
                            />
                          )}
                        />
                      </div>

                      <div className='flex items-center gap-4 mt-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Example
                          <span className='text-red-500'>*</span>
                        </span>
                        <Controller
                          name={`questions.${questionIndex}.example`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...register(`questions.${questionIndex}.example`)}
                              className='form-input w-full text-gray-300'
                              placeholder='Enter Example'
                            />
                          )}
                        />
                      </div>

                      <div className='flex items-center gap-4 mt-4'>
                        <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                          Image Link
                          <span className='text-red-500'>*</span>
                        </span>
                        <Controller
                          name={`questions.${questionIndex}.image_link`}
                          control={control}
                          render={({ field }) => (
                            <input
                              {...register(`questions.${questionIndex}.image_link`)}
                              className='form-input w-full text-gray-300'
                              placeholder='Enter Image Link'
                            />
                          )}
                        />
                      </div>

                      <button
                        type='button'
                        onClick={() => removeQuestion(questionIndex)}
                        className='mt-4 px-4 py-2 bg-red-500 text-white rounded'
                      >
                        Remove Question
                      </button>
                    </Accordion>
                  ))}
                </div>

                <button
                  type='button'
                  onClick={() =>
                    appendQuestion({
                      question: '',
                      options: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
                      correct_option: '',
                    })
                  }
                  className='mt-4 mr-4 px-4 py-2 bg-blue-500 text-white rounded'
                >
                  Add Question
                </button>

                <button type='submit' className='mt-4 px-4 py-2 rounded-md bg-green-500 text-white'>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const OptionFields = ({ nestIndex, control, register, errors }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions.${nestIndex}.options`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} className='flex items-center gap-4 mb-2'>
            <span className='w-48 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
              Option {k + 1} <span className='text-red-500'>*</span>
            </span>
            <input
              {...register(`questions.${nestIndex}.options.${k}.text`, {
                required: 'Option text is required',
              })}
              className='form-input w-full text-gray-300'
              placeholder={`Option ${k + 1}`}
            />
            <button type='button' onClick={() => remove(k)} className='text-red-500'>
              <BadgeX />
            </button>
          </div>
        );
      })}
      {errors.questions?.[nestIndex]?.options && (
        <span className='text-red-500'>All options must be filled</span>
      )}
      <button
        type='button'
        onClick={() => append({ text: '' })}
        className='mt-2 px-4 py-2 bg-blue-500 text-white rounded'
      >
        Add Option
      </button>
    </div>
  );
};

export default QuizContentEdit;
