import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CategoryTable from '../../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../../components/Shared/Modals/CategoryModal';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { setSinglePartherCourse } from '../../../slices/coursesSlice';
import {
    updatePartnersCourse,
    addPartnerCourse,
    deletePartnerCourse,
} from '../../../services/operations/courses';


const PartherCourses = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { PartherCourses, SinglePartherCourse } = useSelector((state) => state.courses);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitted },
        reset,
    } = useForm();

    const handleAddProblem = (e) => {
        e.stopPropagation();
        setIsEdit(false);
        dispatch(setSinglePartherCourse({}));
        reset({
            name: '',
            slug: '',
            image_link: '',
            hasQuiz: false,
            totalQuiz: 0,
            totalVideos: 0,
            totalDownloadableResources: 0,
            dollar_price: 0,
            price: 0,
            strike_off_price: 0,
            dollar_strike_off_price: 0,
        });
        setModalOpen(true);
    };
    useEffect(() => {
        reset(SinglePartherCourse)
    }, [reset, SinglePartherCourse])

    const onSubmit = (data) => {
        const { name, slug } = data;
        const metaData = {
            image_link: data?.image_link || '',
            hasQuiz: data?.hasQuiz || false,
            totalQuiz: data?.totalQuiz || 0 ,
            totalVideos: data?.totalVideos || 0 ,
            totalDownloadableResources: data?.totalDownloadableResources || 0,
            dollar_price: data?.dollar_price || 0,
            price: data?.price || 0,
            strike_off_price: data?.strike_off_price || 0,
            dollar_strike_off_price: data?.dollar_strike_off_price || 0,
        }
        if (isEdit) {
            const { id } = SinglePartherCourse;
            dispatch(updatePartnersCourse({ id, name, slug, metaData }));
            setModalOpen(false);
        } else {
            dispatch(addPartnerCourse({ name, slug, metaData }, setModalOpen, reset));
        }
    };

    const handleSubjectEdit = (e, courseData) => {
        e.stopPropagation();
        const { id, name, slug, metaData } = courseData;
        const data = {
            id,
            name,
            slug,
            image_link: metaData?.image_link || '',
            hasQuiz: metaData?.hasQuiz || false,
            totalQuiz: metaData?.totalQuiz || 0 ,
            totalVideos: metaData?.totalVideos || 0 ,
            totalDownloadableResources: metaData?.totalDownloadableResources || 0,
            dollar_price: metaData?.dollar_price || 0,
            price: metaData?.price || 0,
            strike_off_price: metaData?.strike_off_price || 0,
            dollar_strike_off_price: metaData?.dollar_strike_off_price || 0,
        };
        reset(data);
        dispatch(setSinglePartherCourse(data));
        setModalOpen(true);
        setIsEdit(true);
    };

    const handleDeleteSubject = (course_slug, setDeleteModalOpen) => {
        dispatch(deletePartnerCourse(course_slug));
        setDeleteModalOpen(false);
    };

    return (
        <div className='flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className='px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto'>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <h2 className='text-2xl font-bold text-[#212121] dark:text-zinc-200'>My Courses</h2>
                        </div>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <button
                                onClick={handleAddProblem}
                                className='bg-[#FFD3B5] dark:bg-zinc-800 text-[#212121] dark:text-zinc-200 font-semibold py-2 px-4 rounded-lg'
                            >
                                Add Courses
                            </button>
                        </div>

                        <div className='flex gap-y-2 flex-col justify-start'>
                            {PartherCourses.length > 0 ? (
                                <div className='flex flex-col p-4 '>
                                    <CategoryTable
                                        page='MyCourses'
                                        setIsEdit={setIsEdit}
                                        topics={PartherCourses}
                                        setModalOpen={setModalOpen}
                                        modalOpen={modalOpen}
                                        handleEdit={handleSubjectEdit}
                                        handleDelete={handleDeleteSubject}
                                    />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Subjects to show
                                </div>
                            )}
                        </div>
                        <CategoryModal title='Add Courses' setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Name
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='name'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='name'
                                                    type='text'
                                                    name='name'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='name'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        const filteredValue = e.target.value
                                                            .toLowerCase()
                                                            .replace(/[\s/]/g, '-');
                                                        setValue('slug', filteredValue);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.name && (
                                            <p className='text-red-600 text-sm italic'>{errors.name.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Slug
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='slug'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='slug'
                                                    type='text'
                                                    name='slug'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='slug'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                validate: (value) => {
                                                    const validSlugRegex = /^[a-zA-Z0-9_-]+$/;
                                                    if (!validSlugRegex.test(value)) {
                                                        return 'Slug can only contain letters, numbers, underscores, and hyphens';
                                                    }
                                                    return true;
                                                },
                                            }}
                                        />
                                        {isSubmitted && errors.slug && (
                                            <p className='text-red-600 text-sm italic'>{errors.slug?.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Image Link
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='image_link'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='image_link'
                                                    type='text'
                                                    name='image_link'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Image Link'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.image_link && (
                                            <p className='text-red-600 text-sm italic'>{errors.image_link.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Videos
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalVideos'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalVideoss'
                                                    type='number'
                                                    name='totalVideoss'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Videos'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalVideos && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalVideos.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Downloadable Resources
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalDownloadableResources'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalDownloadableResources'
                                                    type='number'
                                                    name='totalDownloadableResources'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Downlaodable Resources'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalDownloadableResources && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalDownloadableResources.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Total Quizes
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='totalQuiz'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='totalQuiz'
                                                    type='number'
                                                    name='totalQuiz'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Total Quiz'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.totalQuiz && (
                                            <p className='text-red-600 text-sm italic'>{errors.totalQuiz.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Price
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='price'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='price'
                                                    type='number'
                                                    name='price'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Price'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.price && (
                                            <p className='text-red-600 text-sm italic'>{errors.price.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Dollar Price
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='dollar_price'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='dollar_price'
                                                    type='number'
                                                    name='dollar_price'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Dollar Price'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.dollar_price && (
                                            <p className='text-red-600 text-sm italic'>{errors.dollar_price.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                       Strike Off Price
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='strike_off_price'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='strike_off_price'
                                                    type='number'
                                                    name='strike_off_price'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Strike Off Price'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.strike_off_price && (
                                            <p className='text-red-600 text-sm italic'>{errors.strike_off_price.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                       Dollar Strike Off Price
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='dollar_strike_off_price'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='dollar_strike_off_price'
                                                    type='number'
                                                    name='dollar_strike_off_price'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='Dollar Strike Off Price'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.dollar_strike_off_price && (
                                            <p className='text-red-600 text-sm italic'>{errors.dollar_strike_off_price.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Has Quizes?
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='hasQuiz'
                                            control={control}
                                            render={({ field }) => (
                                                <select {...field} className='bg-zinc-900 rounded-md w-full'>
                                                    <option value='true'>Yes</option>
                                                    <option value='false'>No</option>
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors.hasQuiz && (
                                            <p className='text-red-600 text-sm italic'>{errors.hasQuiz.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mt-6'>
                                    <div className='w-full px-3'>
                                        <button type='submit' className='btn-brand-2 w-full'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default PartherCourses;
