import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import RichEditor from '../../../components/RichEditor/RichEditor';
import { BadgeX, Plus, Tag, X } from 'lucide-react';
import { updateDSAProblem } from '../../../services/operations/new-syllabus';
import { useDispatch } from 'react-redux';

const ContentEdit = (props) => {
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            problem_name: CurrentProblem?.problem_name || '',
            slug: CurrentProblem?.slug || '',
            hasIDE: CurrentProblem?.hasIDE === 1 ? true : false,
            difficulty: CurrentProblem?.problem_data?.difficulty || 'Easy',
            company_tags: CurrentProblem?.company_tags || [],
            problem_statement: CurrentProblem?.problem_data?.problem_statement || '',
            examples: CurrentProblem?.problem_data?.examples || [],
            constraints: CurrentProblem?.problem_data?.constraints || '',
            notes: CurrentProblem?.problem_data?.notes || '',
            facts: CurrentProblem?.problem_data?.facts || '',
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'examples',
    });


    useEffect(() => {
        if (Object.keys(CurrentProblem).length > 0) {
            
            reset({
                problem_name: CurrentProblem?.problem_name || '',
                slug: CurrentProblem?.slug || '',
                hasIDE: CurrentProblem?.hasIDE === 1 ? true : false,
                difficulty: CurrentProblem?.problem_data?.difficulty || 'Easy',
                company_tags: CurrentProblem?.company_tags || [],
                problem_statement: CurrentProblem?.problem_data?.problem_statement || '',
                examples: CurrentProblem?.problem_data?.examples || [],
                constraints: CurrentProblem?.problem_data?.constraints || '',
                notes: CurrentProblem?.problem_data?.notes || '',
                facts: CurrentProblem?.problem_data?.facts || '',
            });
        }
    }, [CurrentProblem, reset]);

    const onEditorContentChanged = ({ name, html }) => {
        setValue(name, html);
    };

    const inputStructure = [
        {
            type: 'select',
            name: 'difficulty',
            options: ['Easy', 'Medium', 'Hard'],
        },
        {
            type: 'select',
            name: 'company_tags',
            options: [
                'Apple', 'Microsoft', 'Google', 'Amazon', 'Meta', 'NVIDIA', 'Samsung', 
                'TSMC', 'Intel', 'Oracle', 'IBM', 'Dell Technologies', 'HP Inc.', 
                'Cisco', 'SAP', 'Adobe', 'PayPal', 'Salesforce', 'TikTok', 'Uber', 
                'Twitter', 'Zoom', 'Square', 'Shopify', 'Spotify', 'Alibaba', 'Tencent', 
                'Netflix', 'Baidu', 'Sony', 'Lyft', 'Atlassian', 'Palantir', 
                'ServiceNow', 'Infosys', 'Capgemini', 'Fujitsu', 'Accenture', 
                'Pinterest', 'CrowdStrike', 'AMD', 'SpaceX', 'Stripe', 
                'Snowflake', 'GitLab', 'MongoDB', 'Qualcomm', 'Reddit', 'Slack'
            ]
        },
        {
            type: 'editor',
            name: 'problem_statement',
        },
        {
            type: 'editor',
            name: 'examples',
        },
        {
            type: 'editor',
            name: 'constraints',
        },
        {
            type: 'editor',
            name: 'notes',
        },
        {
            type: 'input',
            name: 'facts',
        },
    ];

    const dispatch = useDispatch();
    const onSubmit = (data) => {
        const problemData = {
            type: 'content',
            id: CurrentProblem.id,
            problem_name: data.problem_name,
            slug: data.slug,
            hasIDE: data.hasIDE ? 1 : 0,
            problem_data: {
                problem_statement: data.problem_statement,
                examples: data.examples,
                constraints: data.constraints,
                notes: data.notes,
                facts: data.facts,
                difficulty: data.difficulty,
            },
            company_tags: data.company_tags,
        };
        dispatch(updateDSAProblem(problemData));
        
    };

    return (
        <div className='flex flex-col gap-y-6'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col mt-6 gap-y-6'>
                <div className='flex flex-col gap-y-2 mt-2'>
                    <div className='flex gap-5 mb-8'>
                        <div className='w-1/2'>
                            <div className='flex items-center gap-x-4'>
                                <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                    Problem Name <span className='text-red-500'>*</span>
                                </span>
                                {errors.problem_name && (
                                    <span className='text-red-500'>
                                        Please enter at least 10 characters.
                                    </span>
                                )}
                            </div>
                            <Controller
                                name='problem_name'
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        name='problem_name'
                                        className='form-input w-full text-gray-300'
                                        placeholder='problem_name'
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'problem_name is required',
                                }}
                            />
                        </div>

                        <div className='w-1/2'>
                            <div className='flex items-center gap-x-4'>
                                <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                    Slug <span className='text-red-500'>*</span>
                                </span>
                                {errors.slug && (
                                    <span className='text-red-500'>
                                        Please enter at least 10 characters.
                                    </span>
                                )}
                            </div>
                            <Controller
                                name='slug'
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        name='slug'
                                        className='form-input w-full text-gray-300'
                                        placeholder='slug'
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'slug is required',
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex gap-5 items-center mb-4'>
                        <div className='flex items-center gap-x-4'>
                            <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                hasIDE <span className='text-red-500'>*</span>
                            </span>
                            {errors.hasIDE && (
                                <span className='text-red-500'>
                                    Please enter at least 10 characters.
                                </span>
                            )}
                        </div>
                        <Controller
                            name='hasIDE'
                            control={control}
                            render={({ field }) => (
                                <input
                                    type='checkbox'
                                    name='hasIDE'
                                    className='h-10 w-10 dark:text-blue-600'
                                    checked={field.value}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-col gap-12'>
                        {inputStructure.map((input) => {
                            return (
                                <div key={input.name}>
                                    {input.name === 'examples' ? (
                                        <>
                                            <div>
                                                <div className='flex justify-between'>
                                                    <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium mb-4'>
                                                        Examples{' '}
                                                        <span className='text-red-500'>*</span>
                                                    </label>
                                                </div>
                                                <div className='flex flex-col gap-16'>
                                                    {fields.map((field, index) => (
                                                        <div key={field.id}>
                                                            <div className='flex justify-between'>
                                                                <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                                                    Example {index + 1}{' '}
                                                                    <span className='text-red-500'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <BadgeX
                                                                    className='stroke-red-600'
                                                                    size={18}
                                                                    onClick={() => remove(index)}
                                                                />
                                                            </div>
                                                            <div className='flex flex-row'>
                                                                <div className='w-full'>
                                                                    <Controller
                                                                        name={`examples.${index}`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <RichEditor
                                                                                {...field}
                                                                                options='Discussion'
                                                                                onChange={({
                                                                                    html,
                                                                                }) =>
                                                                                    field.onChange(
                                                                                        html,
                                                                                    )
                                                                                }
                                                                                height='rich'
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.examples?.[index] && (
                                                                <span className='text-red-500'>
                                                                    {errors.examples[index].message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                <span>
                                                    <div>
                                                        <button
                                                            type='button'
                                                            onClick={() => append(' ')}
                                                            className='btn mt-20 bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                                        >
                                                            <Plus
                                                                size={18}
                                                                className='stroke-zinc-600'
                                                            />
                                                        </button>
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='flex items-center gap-x-4'>
                                                <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                                    {input.name}{' '}
                                                    <span className='text-red-500'>*</span>
                                                </span>
                                                {errors[input.name] && (
                                                    <span className='text-red-500'>
                                                        Please enter at least 10 characters.
                                                    </span>
                                                )}
                                            </div>
                                            {input.type === 'editor' ? (
                                                <RichEditor
                                                    name={input.name}
                                                    value={
                                                        CurrentProblem?.problem_data &&
                                                        (CurrentProblem?.problem_data[
                                                            `${input.name}`
                                                        ] ||
                                                            '')
                                                    }
                                                    options='Discussion'
                                                    {...register(input.name)}
                                                    onChange={onEditorContentChanged}
                                                    height='rich'
                                                />
                                            ) : input.type === 'select' ? (
                                                input.name === 'company_tags' ? (
                                                    <CompanyTagsSelector
                                                        control={control}
                                                        name='company_tags'
                                                        options={input.options}
                                                    />
                                                ) : (
                                                    <>
                                                        <Controller
                                                            name={input.name}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <select
                                                                    name={input.name}
                                                                    className='form-input w-full text-gray-300'
                                                                    placeholder={input.name}
                                                                    {...field}
                                                                >
                                                                    {input.options.map((option) => (
                                                                        <option
                                                                            value={option}
                                                                        >
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        />
                                                        <div className='flex gap-4 mb-14 sm:mb-0'>
                                                            <div className='relative w-full sm:w-[200px]'></div>
                                                        </div>
                                                    </>
                                                )
                                            ) : (
                                                <Controller
                                                    name={input.name}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            type='text'
                                                            name={input.name}
                                                            className='form-input w-full text-gray-300'
                                                            placeholder={input.name}
                                                            {...field}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: `${input.name} is required`,
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <button
                    type='submit'
                    className='bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mt-12'
                >
                    <span>Save</span>
                </button>
            </form>
        </div>
    );
};

export default ContentEdit;

const CompanyTagsSelector = ({ control, name, options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className='relative'>
                    {field.value.length > 0 && (
                        <div className='mt-2 flex flex-wrap gap-4'>
                            {field.value.map((tag) => (
                                <span
                                    key={tag}
                                    className='inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 mb-5'
                                >
                                    {tag}
                                    <button
                                        type='button'
                                        onClick={() =>
                                            field.onChange(field.value.filter((t) => t !== tag))
                                        }
                                        className='ml-1 inline-flex items-center p-0.5 rounded-full text-blue-400 hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none'
                                    >
                                        <X size={14} />
                                    </button>
                                </span>
                            ))}
                        </div>
                    )}
                    <button
                        type='button'
                        onClick={() => setIsOpen(!isOpen)}
                        className='w-full px-4 py-2 text-left  border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                    >
                        <div className='flex items-center justify-between'>
                            <span className='flex items-center gap-2 text-gray-700 dark:text-gray-300'>
                                <Tag size={16} />
                                {field.value.length > 0
                                    ? `${field.value.length} tags selected`
                                    : 'Select company tags'}
                            </span>
                            <svg
                                className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                    clipRule='evenodd'
                                />
                            </svg>
                        </div>
                    </button>

                    {isOpen && (
                        <div className='absolute z-10 w-full mt-1 bg-zinc-800 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg'>
                            <div className='p-2'>
                                <input
                                    type='text'
                                    placeholder='Search tags...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className='w-full px-3 py-2 border border-zinc-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-zinc-700'
                                />
                            </div>
                            <div className='max-h-60 overflow-y-auto'>
                                {options
                                    .filter((tag) =>
                                        tag.toLowerCase().includes(searchTerm.toLowerCase()),
                                    )
                                    .map((tag) => (
                                        <label
                                            key={tag}
                                            className='flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer'
                                        >
                                            <input
                                                type='checkbox'
                                                checked={field.value.includes(tag)}
                                                onChange={() => {
                                                    const newValue = field.value.includes(tag)
                                                        ? field.value.filter((t) => t !== tag)
                                                        : [...field.value, tag];
                                                    field.onChange(newValue);
                                                }}
                                                className='mr-2  dark:text-blue-500'
                                            />
                                            <span className='text-gray-700 dark:text-gray-300'>
                                                {tag}
                                            </span>
                                        </label>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        />
    );
};
