const BASE_URL = process.env.REACT_APP_BACKEND_URL_PROD;
const BASE_URL_DEV = process.env.REACT_APP_BACKEND_URL;
const JUDGE_URL = process.env.REACT_APP_JUDGE_URL;
const JUDGE_URLTWO = process.env.REACT_APP_JUDGE_URLTWO;

export const authEndpoints = {
  LOGIN_IN: BASE_URL + '/auth/login',
  LOGOUT_IN: BASE_URL + '/auth/logout',
  SET_USER_IN: BASE_URL + '/auth/addUser',
  SET_USER_IN_DEV: BASE_URL_DEV + '/auth/addUser',
  ALL_USERS: BASE_URL + '/profile/getAllUsers/',
  DELETE_USER: BASE_URL + '/profile/deleteUser/',
  DELETE_USER_DEV: BASE_URL_DEV + '/profile/deleteUser/',
};

export const categoriesEndpoints = {
  GET_ALL_CAT: BASE_URL + '/categories/getAllCategories',
  ADD_CAT: BASE_URL + '/categories/addCategory',
  UPDATE_CAT: BASE_URL + '/categories/editCategory/',
  DEL_CAT: BASE_URL + '/categories/deleteCategory/',
};
export const subCategoriesEndpoints = {
  GET_ALL_SUB_CAT: BASE_URL + '/subCategories/getCategoriesWithSubcategories',
  ADD_SUB_CAT: BASE_URL + '/subCategories/addSubcategory',
  UPDATE_SUB_CAT: BASE_URL + '/subCategories/editSubcategory/',
  DEL_SUB_CAT: BASE_URL + '/subCategories/deleteSubcategory/',
};

export const categoriesEndpointsDev = {
  GET_ALL_CAT_DEV: BASE_URL_DEV + '/categories/getAllCategories',
  ADD_CAT_DEV: BASE_URL_DEV + '/categories/addCategory',
  UPDATE_CAT_DEV: BASE_URL_DEV + '/categories/editCategory/',
  DEL_CAT_DEV: BASE_URL_DEV + '/categories/deleteCategory/',
};
export const subCategoriesEndpointsDev = {
  GET_ALL_SUB_CAT_DEV: BASE_URL_DEV + '/subCategories/getCategoriesWithSubcategories',
  ADD_SUB_CAT_DEV: BASE_URL_DEV + '/subCategories/addSubcategory',
  UPDATE_SUB_CAT_DEV: BASE_URL_DEV + '/subCategories/editSubcategory/',
  DEL_SUB_CAT_DEV: BASE_URL_DEV + '/subCategories/deleteSubcategory/',
};
export const problemsEndpoints = {
  GET_ALL_PROBLEM: BASE_URL + '/problems/getAllProblems',
  GET_DISLIKED_PROBLEMS: BASE_URL + '/problems/getDislikedProblems',
  ADD_PROBLEM: BASE_URL + '/problems/addProblem',
  UPDATE_PROBLEM: BASE_URL + '/problems/editProblem/',
  DEL_PROBLEM: BASE_URL + '/problems/deleteProblem/',
  ADD_PROBLEM_VIDEO_THUMB: BASE_URL + '/problems/addVideosToProblem',
  ADD_PROBLEM_EDITORIALS: BASE_URL + '/problems/addEditorialsToProblem',
  GET_PROBLEM: BASE_URL + '/problems/getSingleProblem/',
  GET_DELETED_PROBLEM: BASE_URL + '/problems/getDeletedProblems',
  ADD_DELETED_PROBLEM: BASE_URL + '/problems/addDeletedProblem/',
  DEL_DELETED_PROBLEM: BASE_URL + '/problems/deleteDeletedProblem/',
};
export const problemsEndpointsDev = {
  GET_ALL_PROBLEM_DEV: BASE_URL_DEV + '/problems/getAllProblems',
  GET_DISLIKED_PROBLEMS_DEV: BASE_URL_DEV + '/problems/getDislikedProblems',
  ADD_PROBLEM_DEV: BASE_URL_DEV + '/problems/addProblem',
  UPDATE_PROBLEM_DEV: BASE_URL_DEV + '/problems/editProblem/',
  DEL_PROBLEM_DEV: BASE_URL_DEV + '/problems/deleteProblem/',
  ADD_PROBLEM_VIDEO_THUMB_DEV: BASE_URL_DEV + '/problems/addVideosToProblem',
  ADD_PROBLEM_EDITORIALS_DEV: BASE_URL_DEV + '/problems/addEditorialsToProblem',
  GET_PROBLEM_DEV: BASE_URL_DEV + '/problems/getSingleProblem/',
  GET_DELETED_PROBLEM_DEV: BASE_URL_DEV + '/problems/getDeletedProblems',
  ADD_DELETED_PROBLEM_DEV: BASE_URL_DEV + '/problems/addDeletedProblem/',
  DEL_DELETED_PROBLEM_DEV: BASE_URL_DEV + '/problems/deleteDeletedProblem/',
};
export const problemSetterEndpointsDev = {
  GET_PROBLEM_DETAILS_DEV: BASE_URL_DEV + '/problemSetter/problemDetails/',
  UPDATE_PROBLEM_SETTER_DATA_DEV: BASE_URL_DEV + '/problemSetter/editProblem',
};
export const problemSetterEndpoints = {
  GET_PROBLEM_DETAILS: BASE_URL + '/problemSetter/problemDetails/',
  UPDATE_PROBLEM_SETTER_DATA: BASE_URL + '/problemSetter/editProblem',
};
export const sessionsEndpoints = {
  GET_SESSIONS: BASE_URL + '/sessions/getAllSessions',
  ADD_SESSION: BASE_URL + '/sessions/createSession',
  UPDATE_SESSION: BASE_URL + '/sessions/updateSession/',
  DEL_SESSION: BASE_URL + '/sessions/deleteSession/',
  GET_SESSION: BASE_URL + '/sessions/getSession/',
  ADD_SESSION_DEV: BASE_URL_DEV + '/sessions/createSession',
  UPDATE_SESSION_DEV: BASE_URL_DEV + '/sessions/updateSession/',
  GET_SESSION_DEV: BASE_URL_DEV + '/sessions/getSession/',
  DEL_SESSION_DEV: BASE_URL_DEV + '/sessions/deleteSession/',
};
export const notificationEndpoints = {
  ADD_NOTIFICATION: BASE_URL + '/notifications/addNotification',
  UPDATE_NOTIFICATION: BASE_URL + '/notifications/updateNotification/',
  GET_NOTIFICATIONS: BASE_URL + '/notifications/showAllNotifications',
  DEL_NOTIFICATION: BASE_URL + '/notifications/deleteNotification/',
  SEND_EMAIL_TO_PREMIUM_USERS: BASE_URL + '/notifications/sendEmailsToPremiumUsers',
  ADD_PLUS_UPDATE: BASE_URL_DEV + '/notifications/add-plusUpdate',
  EDIT_PLUS_UPDATE: BASE_URL_DEV + '/notifications/edit-plusUpdate/',
  REMOVE_PLUS_UPDATE: BASE_URL_DEV + '/notifications/delete-plusUpdate/',
  GET_ALL_PLUS_UPDATES: BASE_URL_DEV + '/notifications/getAllPlusUpdates',
};
export const referralEndpoints = {
  GET_AFFILIATE_SALES: BASE_URL + '/affiliates/affiliate-sales',
  GENERATE_SHEET: BASE_URL + '/affiliates/generate-payment-sheet',
  DEACTIVATE_REFERRAL_CODE: BASE_URL + '/affiliates/deactivate-coupon',
  REACTIVATE_REFERRAL_CODE: BASE_URL + '/affiliates/reactivate-coupon',
};
export const bugReportsEndpoints = {
  GET_REPORTED_BUGS: BASE_URL + '/bugs/reported-bugs/',
  MARK_STATUS: BASE_URL + '/bugs/bug-status',
  GET_ALL_BUGS: BASE_URL + '/bugs/get-all-reported-bugs',
  SEND_EMAIL: BASE_URL + '/bugs/send-email',
  GET_BUG_BY_ID: BASE_URL + '/bugs/get-bug',
};
export const grantAccessEndpoints = {
  ADD_USER: BASE_URL + '/access/addUser',
  EDIT_USER: BASE_URL + '/access/updateUser',
  DELETE_USER: BASE_URL + '/access/deleteUser/',
  GET_ALL_USERS: BASE_URL + '/access/getAllUsers',
  REMOVE_PREMIUM_ACCESS: BASE_URL + '/access/removePremiumAccess',
};
export const salesTrackerEndpoints = {
  GET_TOTAL_SALES: BASE_URL + '/revenue/total-sales/',
  GET_STATEWISE_SALES: BASE_URL + '/revenue/sales-in-state',
};
export const commentsEndpoints = {
  GET_DOUBT_COST_DAYWISE: BASE_URL + '/comments/doubt-cost',
  GET_DISCUSSIONS: BASE_URL + '/comments/discussions',
  DEL_DISCUSSION: BASE_URL + '/comments/discussions/delete',
};
export const judgeStatsEndpoints = {
  GET_JUDGE_STATS: BASE_URL + '/judgeStats/judge-stats',
};

export const configEndpoints = {
  ADD_CONFIG: BASE_URL + '/configs/addConfigValue',
  EDIT_CONFIG: BASE_URL + '/configs/updateConfig/',
  GET_ALL_CONFIG: BASE_URL + '/configs/getConfigDetails',
  DELETE_CONFIG: BASE_URL + '/configs/deleteConfig/',
};
export const clearCacheEndpoints = {
  CLEAR_CACHE: BASE_URL + '/problems/clearCache',
  CLEAR_CACHE_JUDGE: JUDGE_URL + '/cacheClearJudge',
  CLEAR_CACHE_JUDGETWO: JUDGE_URLTWO + '/cacheClearJudge',
};
export const premiumEndpoints = {
  ADD_PLAN: BASE_URL + '/premium/add-plan',
  EDIT_PLAN: BASE_URL + '/premium/edit-plan',
  DELETE_PLAN: BASE_URL + '/premium/delete-plan',
  GET_ALL_PLANS: BASE_URL + '/premium/all-plans',

  GET_ALL_COUPON_CODES: BASE_URL + '/premium/all-coupons',
  ADD_COUPON_CODE: BASE_URL + '/premium/add-coupon',
  EDIT_COUPON_CODE: BASE_URL + '/premium/edit-coupon',
  DELETE_COUPON_CODE: BASE_URL + '/premium/delete-coupon',
};
export const userStatsEndpoints = {
  GET_SUBMISSION_STATS: BASE_URL + '/userStats/submission-stats',
  GET_DOUBT_STATS: BASE_URL + '/userStats/doubt-stats',
  GET_ACTIVE_USERS_STATS: BASE_URL + '/userStats/active-user-stats',
  GET_USERS_PROGRESS_STATS: BASE_URL + '/userStats/user-progress-stats',
  GET_AVERAGE_RATING: BASE_URL + '/userStats/feedback-stats',
  GET_RECENT_RATINGS: BASE_URL + '/userStats/feedback-recent/'
};
export const successStoriesEndpoints = {
  GET_PENDING_STORIES: BASE_URL + '/stories/pending',
  GET_APPROVED_STORIES: BASE_URL + '/stories/approved',
  GET_DISAPPROVED_STORIES: BASE_URL + '/stories/disapproved',
  UPDATE_STATUS: BASE_URL + '/stories/update-status',
};
export const Errors = {
  GET_ERRORS : BASE_URL + '/errors?',
}


export const DSA = {
  GET_ALL_DSA_PROBLEMS: BASE_URL_DEV + '/v2/problems/getAllDSAProblems',
  GET_SINGLE_DSA_PROBLEMS: BASE_URL_DEV + '/v2/problems/getSingleDSAProblem/',
  ADD_DSA_PROBLEM: BASE_URL_DEV + '/v2/problems/addDSAProblem',
  UPDATE_DSA_PROBLEM: BASE_URL_DEV + '/v2/problems/editDSAProblem/',
  DELETE_DSA_PROBLEM: BASE_URL_DEV + '/v2/problems/deleteDSAProblem/',

};

export const THEORY = {
  GET_ALL_THEORY_PROBLEMS: BASE_URL_DEV + '/v2/problems/getAllTheoryProblems',
  GET_SINGLE_THEORY_PROBLEMS: BASE_URL_DEV + '/v2/problems/getSingleTheoryProblem/',
  ADD_THEORY_PROBLEM: BASE_URL_DEV + '/v2/problems/addTheoryProblem',
  UPDATE_THEORY_PROBLEM: BASE_URL_DEV + '/v2/problems/editTheoryProblem/',
  DELETE_THEORY_PROBLEM: BASE_URL_DEV + '/v2/problems/deleteTheoryProblem/',
}

export const QUIZ = {
  GET_ALL_QUIZ_PROBLEMS: BASE_URL_DEV + '/v2/problems/getAllQuizProblems',
  GET_SINGLE_QUIZ_PROBLEMS: BASE_URL_DEV + '/v2/problems/getSingleQuizProblem/',
  ADD_QUIZ_PROBLEM: BASE_URL_DEV + '/v2/problems/addQuizProblem',
  UPDATE_QUIZ_PROBLEM: BASE_URL_DEV + '/v2/problems/editQuizProblem/',
  DELETE_QUIZ_PROBLEM: BASE_URL_DEV + '/v2/problems/deleteQuizProblem/',
}


export const TOPICS = {
  GET_ALL_CATEGORIES_V2: BASE_URL_DEV + '/v2/problems/getAllCategories',
  ADD_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/addCategory',
  UPDATE_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/editCategory/',
  DELETE_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/deleteCategory/',


  GET_ALL_SUB_CATEGORIES_V2: BASE_URL_DEV + '/v2/problems/getAllSubCategories',
  ADD_SUB_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/addSubCategory',
  UPDATE_SUB_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/editSubCategory/',
  DELETE_SUB_CATEGORY_V2: BASE_URL_DEV + '/v2/problems/deleteSubCategory/',

  GET_ALL_TOPICS_V2: BASE_URL_DEV + '/v2/problems/getAllTopics',
  ADD_TOPIC_V2: BASE_URL_DEV + '/v2/problems/addTopic',
  UPDATE_TOPIC_V2: BASE_URL_DEV + '/v2/problems/editTopic/',
  DELETE_TOPIC_V2: BASE_URL_DEV + '/v2/problems/deleteTopic/',
  REMOVE_PROBLEM_FROM_ORDER: BASE_URL_DEV + '/v2/problems/removeProblemFromOrder/',
}

export const SUBJECT_ORDER = {
  GET_ALL_SUBJECT_PROBLEMS: BASE_URL_DEV + '/v2/problems/getAllSubjectProblems/',

  SET_PROBLEM_ORDER: BASE_URL_DEV + '/v2/problems/setProblemOrder/',

  SET_SUB_CATEGORY_PROBLEM_ORDER: BASE_URL_DEV + '/v2/problems/setSubCategoryProblemOrder/',

  SET_CATEGORY_PROBLEM_ORDER: BASE_URL_DEV + '/v2/problems/setCategoryProblemOrder/',

}

export const manageCoursesEndpoints = {

  // add courses
  ADD_MY_COURSE: BASE_URL_DEV + '/v2/problems/addMyCourse',
  ADD_PARTNERS_COURSE: BASE_URL_DEV + '/v2/problems/addPartnerCourse',
  
  // get courses
  GET_ALL_MY_COURSES: BASE_URL_DEV + '/v2/problems/getAllMyCourses',
  GET_ALL_PARTNERS_COURSES: BASE_URL_DEV + '/v2/problems/getAllPartnerCourses',

  // update courses
  UPDATE_PARTNERS_COURSE: BASE_URL_DEV + '/v2/problems/updatePartnerCourse/',
  UPDATE_MY_COURSE: BASE_URL_DEV + '/v2/problems/updateMyCourse/',

  // delete courses
  DELETE_MY_COURSE: BASE_URL_DEV + '/v2/problems/deleteMyCourse/',
  DELETE_PARTNERS_COURSE: BASE_URL_DEV + '/v2/problems/deletePartnerCourse/',
}