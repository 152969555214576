import { setMyCourses, setPartherCourses } from '../../slices/coursesSlice';
import { manageCoursesEndpoints } from '../api';
import toast from 'react-hot-toast';
import { apiConnector } from '../apiConnector';

const {
  GET_ALL_MY_COURSES,
  UPDATE_MY_COURSE,
  DELETE_MY_COURSE,
  ADD_MY_COURSE,
  ADD_PARTNERS_COURSE,
  DELETE_PARTNERS_COURSE,
  GET_ALL_PARTNERS_COURSES,
  UPDATE_PARTNERS_COURSE,
} = manageCoursesEndpoints;

export function getMyCourses() {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_ALL_MY_COURSES);

      if (response?.data.success) {
        dispatch(setMyCourses(response.data.data));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching my courses:', error);
      toast.error('Failed to fetch my courses');
    }
  };
}

export function updateMyCourses(course) {
  return async (dispatch) => {
    try {
      const response = await apiConnector('PUT', UPDATE_MY_COURSE + course.id, course);

      if (response?.data.success) {
        toast.success('Course updated successfully');
        dispatch(getMyCourses());
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error updating my courses:', error);
      toast.error('Failed to update my courses');
    }
  };
}

export const addMyCourse = (course, setModalOpen, reset) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('POST', ADD_MY_COURSE, course);

      if (response?.data.success) {
        toast.success('Course added successfully');
        reset({
          name: '',
          slug: '',
          type: 'CategoryOnly',
          metaData: {
            image_link: '',
            hasQuiz: false,
            totalQuiz: 0,
            totalProblems: 0,
            hasContest: false,
            totalContest: 0,
          },
        });
        dispatch(getMyCourses());
        setModalOpen(false);
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error adding my courses:', error);
      toast.error('Failed to add my courses');
    }
  };
};

export const deleteMyCourse = (slug) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('DELETE', DELETE_MY_COURSE + slug);

      if (response?.data.success) {
        toast.success('Course deleted successfully');
        dispatch(getMyCourses());
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error deleting my courses:', error);
      toast.error('Failed to delete my courses');
    }
  };
};

export const addPartnerCourse = (course, setModalOpen, reset) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('POST', ADD_PARTNERS_COURSE, course);

      if (response?.data.success) {
        toast.success('Parther Course added successfully');
        reset({
          name: '',
          slug: '',
          image_link: '',
          hasQuiz: false,
          totalQuiz: 0,
          totalVideos: 0,
          totalDownloadableResources: 0,
        });
        dispatch(getPartnersCourses());
        setModalOpen(false);
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error adding my courses:', error);
      toast.error('Failed to add my courses');
    }
  };
};

export const deletePartnerCourse = (slug) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('DELETE', DELETE_PARTNERS_COURSE + slug);

      if (response?.data.success) {
        toast.success('Course deleted successfully');
        dispatch(getPartnersCourses());
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error deleting my courses:', error);
      toast.error('Failed to delete my courses');
    }
  };
};

export const getPartnersCourses = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('GET', GET_ALL_PARTNERS_COURSES);

      if (response?.data.success) {
        dispatch(setPartherCourses(response.data.data));
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error fetching my courses:', error);
      toast.error('Failed to fetch my courses');
    }
  };
};

export const updatePartnersCourse = (course) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector('PUT', UPDATE_PARTNERS_COURSE + course.id, course);

      if (response?.data.success) {
        toast.success('Course updated successfully');
        dispatch(getPartnersCourses());
      } else {
        console.warn('No data found in the response');
      }
    } catch (error) {
      console.error('Error updating my courses:', error);
      toast.error('Failed to update my courses');
    }
  };
};
