import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Course: {},
  MyCourses: [],
  SinglePartherCourse : {},
  PartherCourses: []
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState: initialState,
  reducers: {
    setCourse(state, action) {
      state.Course = action.payload;
    },
    setMyCourses(state, action) {
      state.MyCourses = action.payload;
    },
    setSinglePartherCourse(state, action) {
      state.SinglePartherCourse = action.payload;
    },
    setPartherCourses(state, action) {
      state.PartherCourses = action.payload;
    },
  },
});

export const { setCourse, setMyCourses, setSinglePartherCourse, setPartherCourses } = coursesSlice.actions;

export default coursesSlice.reducer;
