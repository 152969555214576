import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { Plus } from 'lucide-react';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSubCategory } from '../../slices/syllabusSlice';
import SubCategoryTable from '../../components/Table/SubCategoryTable/SubCategoryTable';
import { addSubCategory, updateSubCategory } from '../../services/operations/syllabus';
import ContentAccordion from '../../components/Accordion/ContentAccordion';

const SubCategory = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);
    const [catId, setCatId] = useState(null);


    const {isProd} = useSelector((state) => state.auth)
    
    const dispatch = useDispatch()

    const handleSubCategoryModal = (e, category, category_id) => {
        e.stopPropagation();
        const data = {
            category: category,
            name: '',
            rank: 0,
            type: "study",
            slug: '',
        }
        setCatId(category_id)
        // console.log(category_id)
        setIsEdit(false)
        dispatch(setSubCategory(data))
        setSubCategoryOpen(true)
    }

    useEffect(() => {
        dispatch(setSubCategory({}))
    }, [dispatch]);



    const { subCategory, allSubCategories } = useSelector((state) => state.syllabus)
    const { handleSubmit, control, reset, formState: { errors, isSubmitted } } = useForm({ defaultValues: subCategory });

    useEffect(() => {

        reset(subCategory);
    }, [subCategory, reset]);

    const onSubmit = async (data) => {
        if (isEdit && isEdit) {
            const { name, type, rank, slug } = data
            dispatch(updateSubCategory(encodeURIComponent(subCategory.id), subCategory?.categoryId, name, type, slug, rank, setSubCategoryOpen, isProd))
        } else {
            // console.log(type)
            const { name, type, rank, slug } = data
            dispatch(addSubCategory(catId, name, rank, type, slug, setSubCategoryOpen, isProd))

        }
    }
    // console.log(allSubCategories);
    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-zinc-300">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex flex-col gap-y-2 justify-start'>
                            {allSubCategories && allSubCategories.length > 0 ? (
                                <>
                                    {allSubCategories.map((category, index) => (
                                        <div key={index} className=''>
                                            <ContentAccordion title={category.categoryName}>
                                                <div className='flex flex-col items-start p-4 '>
                                                    <button onClick={(e) => handleSubCategoryModal(e, category.categoryName, category.categoryId)} className='bg-brand text-white rounded-lg p-1 ml-auto mb-5'>
                                                        <Plus size={18} />
                                                    </button>
                                                    {category?.subcategories?.length > 0 && (
                                                        <SubCategoryTable setIsEdit={setIsEdit} category={category.categoryName} setModalOpen={setSubCategoryOpen} topics={category?.subcategories} />
                                                    )}
                                                </div>
                                            </ContentAccordion>

                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Category to add a sub category
                                </div>
                            )}
                            <CategoryModal title='Add Sub Category' setModalOpen={setSubCategoryOpen} modalOpen={subCategoryOpen}>
                                <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Category</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="category"
                                                        type="text"
                                                        name="category"
                                                        className="form-input w-full text-gray-300"
                                                        disabled
                                                        placeholder='category'
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'category is required',
                                                }}
                                            />
                                            {isSubmitted && errors.category && <p className="text-red-600 text-sm italic">{errors.category.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        name="name"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='name'
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Name is required',
                                                }}
                                            />
                                            {isSubmitted && errors.name && <p className="text-red-600 text-sm italic">{errors.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Slug</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="slug"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="slug"
                                                        type="text"
                                                        name="slug"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='slug'
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Slug is required',
                                                }}
                                            />
                                            {isSubmitted && errors.slug && <p className="text-red-600 text-sm italic">{errors.slug.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Rank</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="rank"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id="rank"
                                                        type="number"
                                                        name="rank"
                                                        className="form-input w-full text-gray-300"
                                                        placeholder='rank'
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Rank is required',
                                                }}
                                            />
                                            {isSubmitted && errors.rank && <p className="text-red-600 text-sm italic">{errors.rank.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-wrap -mx-3 mb-4">
                                        <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Type</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="type"
                                                control={control}
                                                render={({ field }) => (
                                                    <select
                                                        id="type"
                                                        name="type"
                                                        className="form-select w-full text-gray-300"
                                                        {...field}
                                                    >
                                                        <option value="study">Study</option>
                                                        <option value="contest">Contest</option>
                                                    </select>
                                                )}
                                            />
                                            {isSubmitted && errors.type && <p className="text-red-600 text-sm italic">{errors.type.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <button type="submit" className="btn-brand-2 w-full">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </CategoryModal>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SubCategory